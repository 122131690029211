import request from '@/utils/request'

// 手機版圖庫名稱
export const getCategoryName = function(query) {
  return request({
    url: 'v1/cp168/apis/tukus/categories',
    method: 'get',
    params: {
      id: query.join(',')
    }
  })
}

// 電腦版圖庫列表
export const getTukusList = function(query) {
  return request({
    url: 'v1/cp168/apis/tukus/categoriesByPinyin',
    method: 'get',
    params: query
  })
}

// 電腦版獲取圖庫圖片
export const getTukus = function(query) {
  return request({
    url: 'v1/cp168/apis/tukus/images',
    method: 'get',
    params: query
  })
}
