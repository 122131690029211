<template>
  <div class="content-3__content__item">
    <div class="lottery-card">
      <div class="lottery-card__content">
        <div class="lottery-card__content__icon">
          <nuxt-link :to="`/trend/${lottery.code}`">
            <img class="lottery-img" width="90px" :src="lotteryDetail.iconURL" alt="彩种icon">
          </nuxt-link>
        </div>
        <div class="lottery-card__content__title">
          <div class="lotterybox">
            <div class="lotterybox__lottery-name"><span class="lotterybox__lottery-name__text">{{ lotteryDetail.name }}</span></div>
            <div class="lotterybox__lottery-datenumber"><span class="lotterybox__lottery-datenumber__number">{{ lottery.period }}</span><span class="lotterybox__lottery-datenumber__text">期</span></div>
            <div class="lotterybox__lottery-detail">
              <span v-if="lotteryDetail.code === 'smam6'" class="lotterybox__lottery-detail__text">开奖频率: 每小时</span>
              <span v-else-if="lotteryDetail.categoryCode === 'local' || lotteryDetail.categoryCode === 'china'" class="lotterybox__lottery-detail__text">开奖星期: {{ formatWeek(lotteryDetail.weekdays) }}</span>
              <span v-else-if="lotteryDetail.categoryCode === 'gaopin' || lotteryDetail.categoryCode === 'jisu'" class="lotterybox__lottery-detail__text">开奖频率: {{ formatMinute(lotteryDetail.intervalSeconds) }}</span>
              <span v-else-if="lotteryDetail.closeDay" class="lotterybox__lottery-detail__text">休市: {{ formatClose(lotteryDetail.closeDay) }}</span>
            </div>
          </div>
          <div class="ballnumber">
            <div class="ballbox">
              <template v-for="(ball, ballIndex) in lottery.balls">
                <!-- 前六顆 正碼 -->
                <div v-if="ballIndex !== 6" :key="ballIndex" class="ballbox__item">
                  <template v-if="!isTimeUP">
                    <div class="ball size-large" :class="setBallColor(ball.seBo)">
                      <span class="ball__number">{{ padZero(ball.number) }}</span>
                    </div>
                    <div class="ballbox__item__detail">
                      <span class="detail-text">{{ ball.shengXiao | formatLot }}</span>
                      <span class="detail-text">/</span>
                      <span class="detail-text red">{{ ball.wuXing | formatLot }}</span>
                    </div>
                  </template>

                  <!-- 開獎中特效 -->
                  <template v-else>
                    <div class="ball size-large" :class="setBallColor(randomColor[ballIndex])">
                      <span class="ball__number">{{ padZero(randomNum[ballIndex]) }}</span>
                    </div>
                    <div class="ballbox__item__detail">
                      <span class="detail-text">－</span>
                      <span class="detail-text">/</span>
                      <span class="detail-text red">－</span>
                    </div>
                  </template>
                </div>
              </template>
              <!-- 加號 -->
              <div class="ballbox__item"><img :src="require('@/assets/style/desktop/img/plus.svg')" alt=""></div>
              <!-- 第七顆 特碼 -->
              <div class="ballbox__item">
                <template v-if="!isTimeUP">
                  <div class="ball size-large" :class="setBallColor(lottery.balls[6].seBo)">
                    <span class="ball__number">{{ padZero(lottery.balls[6].number) }}</span>
                  </div>
                  <div class="ballbox__item__detail">
                    <span class="detail-text">{{ lottery.balls[6].shengXiao | formatLot }}</span>
                    <span class="detail-text">/</span>
                    <span class="detail-text red">{{ lottery.balls[6].wuXing | formatLot }}</span>
                  </div>
                </template>

                <!-- 開獎中特效 -->
                <template v-else>
                  <div class="ball size-large" :class="setBallColor(randomColor[6])">
                    <span class="ball__number">{{ padZero(randomNum[6]) }}</span>
                  </div>
                  <div class="ballbox__item__detail">
                    <span class="detail-text">－</span>
                    <span class="detail-text">/</span>
                    <span class="detail-text red">－</span>
                  </div>
                </template>
              </div>
            </div>
            <div class="countdown">
              <CounterDown v-model="isTimeUP" :draw-time="lottery.drawTime" @changeInfo="changeInfo" />
            </div>
          </div>
        </div>
      </div>

      <!-- 总和 ＆ 特码 -->
      <div class="lottery-card__content__form">
        <table>
          <thead>
            <tr>
              <th colspan="4">总和</th>
              <th colspan="2">特码</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{{ lottery.tableInfo.zongHe }}</td>
              <td>{{ lottery.tableInfo.zongHeDanShuang | formatLot }}</td>
              <td>{{ lottery.tableInfo.zongHeDaXiao | formatLot }}</td>
              <td>{{ lottery.tableInfo.zongHeYanSe | formatLot }}</td>
              <td>{{ lottery.tableInfo.teMaDanShuang | formatLot }}</td>
              <td>{{ lottery.tableInfo.teMaDaXiao | formatLot }}</td>
            </tr>
          </tbody>
        </table>
      </div>

      <!-- 开奖视频 ＆ 走势图表 -->
      <div class="lottery-card__content__quickselection">
        <a class="button-primary" @click.prevent="openVideo"><span class="button-primary__text">开奖视频</span></a>
        <nuxt-link class="button-primary" :to="`/trend/${lottery.code}`"><span class="button-primary__text">走势图表</span></nuxt-link>
      </div>
    </div>
    <VideoDialog :show.sync="showVideo" :lottery-code="lottery.code" />
  </div>
</template>

<script>
import CounterDown from '@/components/desktop/common/CounterDown'
import { ruleCodeMapping } from '@/utils/newRuleCodeMapping-Sean'
import VideoDialog from '@/components/desktop/common/VideoDialog'

const formatSeBo = {
  'yanse_hong': 'color--red',
  'yanse_lyu': 'color--green',
  'yanse_lan': 'color--blue'
}

const SeBoArray = ['yanse_hong', 'yanse_lyu', 'yanse_lan']

const weekMap = ['日', '一', '二', '三', '四', '五', '六']

export default {
  name: 'LotteryItem',
  components: {
    CounterDown,
    VideoDialog
  },
  filters: {
    formatLot(value) {
      return ruleCodeMapping[value]
    }
  },
  props: {
    lottery: {
      required: true,
      type: Object,
      default: () => {
        console.error('LotteryItem required key "lottery"')
        return {}
      }
    },
    lotteryDetail: {
      required: true,
      type: Object,
      default: () => {
        console.error('LotteryItem required key "LotteryDetail"')
        return {}
      }
    }
  },
  data() {
    return {
      isTimeUP: false,
      showVideo: false,
      randomNum: [0, 0, 0, 0, 0, 0, 0],
      randomColor: ['yanse_hong', 'yanse_lyu', 'yanse_lan', 'yanse_hong', 'yanse_lyu', 'yanse_lan', 'yanse_hong'],
      randomTimer: null
    }
  },
  watch: {
    isTimeUP(val) {
      if (val) {
        // 模擬攪珠
        this.randomTimer = setInterval(() => {
          this.randomNum = this.randomNum.map(num => Math.ceil(Math.random() * 49))
          this.randomColor = this.randomNum.map(color => SeBoArray[Math.floor(Math.random() * 3)])
        }, 200)
      } else {
        clearInterval(this.randomTimer)
        this.randomTimer = null
      }
    }
  },
  methods: {
    setBallColor(code) {
      return { [formatSeBo[code]]: true }
    },
    changeInfo() {
      this.$emit('changeInfo', this.lottery.code)
    },
    formatWeek(weekArray) {
      return weekArray.length === 7 ? '每日' : weekArray.map(day => weekMap[day]).join('')
    },
    openVideo() {
      this.showVideo = true
    },
    formatMinute(intervalSeconds) {
      return Math.floor(intervalSeconds / 60) + '分'
    },
    padZero(num) {
      return String(num).padStart(2, '0')
    },
    // 格式化休市
    formatClose(val) {
      const strFirst = val.split('\n').map(item => item.split(' ~ ').map(date => date.replace(/^\d{4}\-/, '').replace('-', '/')))
      // 當天日期不顯示區間
      const strNoRepeat = strFirst.map(item => item[0] === item[1] ? [item[0]] : item).map(item => item.join('~'))
      return strNoRepeat.join(' , ')
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
