<template>
  <div class="content-5__content">
    <table>
      <thead>
        <tr>
          <th :colspan="totalCol"><span>{{ tableData.tableName }}</span></th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td
            v-for="(group, index) in tableData.group"
            :key="`group-${index}`"
            :colspan="group.items.length"
            class="red"
            :class="{'blue': group.title ==='蓝肖','green': group.title ==='绿肖'}"
          >
            {{ group.title }}
          </td>
        </tr>
        <tr>
          <td
            v-for="(animal, index) in tdRow"
            :key="`Row-${index}`"
            :class="{
              'red': tableData.tableName === '三色生肖对照表' && 4 > index,
              'blue': tableData.tableName === '三色生肖对照表' && 4 <= index && index < 8,
              'green': tableData.tableName === '三色生肖对照表' && index >= 8,
            }"
          >{{ animal }}</td>
        </tr>
      </tbody>
    </table>

  </div>
</template>

<script>
export default {
  name: 'PropTableString',
  props: {
    tableData: {
      type: Object,
      default: () => {
        return {
          tableName: '',
          group: [
            {
              title: '',
              items: []
            }
          ]
        }
      }
    }
  },
  computed: {
    totalCol() {
      return this.tableData.group.map(item => item.items.length).reduce((a, b) => { return a + b })
    },
    tdRow() {
      return this.tableData.group.map(item => item.items).flat()
    }
  },
  methods: {

  }
}
</script>

