<template>
  <div class="home-skeleton">
    <div class="skeleton-item" style="width: 100%; height: 360px; left: 0px; top: 0px;"></div>
    <div class="skeleton-item" style="width: 72%; height: 700px; left: 0px; top: 375px;"></div>
    <div class="skeleton-item" style="width: 26%; height: 360px; right: 0px; top: 375px;"></div>
    <div class="skeleton-item" style="width: 26%; height: 150px; right: 0px; top: 745px;"></div>
    <div class="skeleton-item" style="width: 26%; height: 150px; right: 0px; top: 905px;"></div>
  </div>
</template>

<script>
export default {
  name: 'HomeLoading',
  data() {
    return {

    }
  }
}
</script>

<style lang="scss">
  @keyframes homeAnimation {
    50% {
      opacity: 0.6;
    }
  }

  .home-skeleton {
    position: relative;
    height: 1200px;
    width: 100%;

    & .skeleton-item {
      position: absolute;
      background-color: #d3d3d3;
      opacity: 1;
      animation: homeAnimation 1.2s ease-in-out infinite;
      transform: none;
      border-radius: 4px;
    }
  }
</style>
