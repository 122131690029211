<template>
  <div class="content-15">
    <div class="content-15__title"><span class="content-15__title__text">预测图库</span></div>
    <div class="content-15__search">
      <div
        v-for="(lottery, index) in lotteryMapping"
        :key="index"
        class="content-15__search__item"
        :class="{ 'active': activedTab === lottery.val }"
        @click="changeLottery(lottery.val)"
      >
        <span class="content-15__search__item__text">{{ lottery.name }}</span>
      </div>
    </div>
    <transition name="layout" mode="out-in">
      <div :key="latestData.latestImageURL" class="content-15__content">
        <div class="content-15__content__imgbox" style="min-height: 224px;">
          <img :src="latestData.latestImageURL" @click="goToTuku">
        </div>
        <div class="content-15__content__imgname">
          <span class="content-15__content__imgname__text">第 {{ latestData.latestPeriod }} 期</span>
        </div>
      </div>
    </transition>

    <!-- 圖片懶加載 -->
    <img v-show="false" :src="latestData.latestImageURL">
  </div>
</template>

<script>
import { getCategoryName } from '@/api/desktop/images'

export default {
  name: 'PredictionTuku',
  data() {
    return {
      activedTab: 'ff6',
      lotteryMapping: [
        { name: '分分六合彩', val: 'ff6' },
        { name: '三分六合彩', val: 'sf6' },
        { name: '极速六合彩', val: 'js6' },
        { name: '澳门分分六合彩', val: 'amff6' },
        { name: '澳门三分六合彩', val: 'amsf6' },
        { name: '澳门五分六合彩', val: 'amwf6' }
      ],
      latestData: {
        colored: 0,
        cpCode: '',
        id: 0,
        latestImageURL: '',
        latestPeriod: 0,
        name: '0',
        prefixPinyin: '',
        topCategoryID: 0
      }
    }
  },
  computed: {
    // 從 store 取各彩種的圖庫 ID
    tukuCategory() {
      return this.$store.state.app.tuku
    }
  },
  mounted() {
    this.getCategoryName(this.tukuCategory[this.activedTab] && this.tukuCategory[this.activedTab].categoryIDs)
  },
  methods: {
    changeLottery(lotteryCode) {
      this.activedTab = lotteryCode
      this.getCategoryName(this.tukuCategory[lotteryCode] && this.tukuCategory[lotteryCode].categoryIDs)
    },
    async getCategoryName(query) {
      if (query) {
        const [data, err] = await getCategoryName(query)
        if (err) {
          console.error(`[error]:`, err)
          return
        }
        // 取得最新一筆資料
        this.latestData = data[0]
      }
    },
    goToTuku() {
      this.$router.push({ path: `/images?lotteryCode=${this.activedTab}` })
    }
  }
}
</script>

<style lang="scss" scoped>
.content-15__content__imgbox {
  &:hover {
    cursor: pointer;
  }
}
</style>
