export const ruleCodeMapping = {
  biggest_lost: '最大遗漏',
  chianHou_chian: '前',
  chianHou_hou: '后',
  cold: '冷',
  daXiaoPan_da: '后多',
  daXiaoPan_he: '前后和',
  daXiaoPan_xiao: '前多',
  danShuangPan_dan: '单多',
  danShuangPan_he: '单双和',
  danShuangPan_shuang: '双多',
  danshuang_dan: '单',
  danshuang_he: '和',
  danshuang_shuang: '双',
  daxiao_da: '大',
  daxiao_he: '和',
  daxiao_xiao: '小',
  dongWu_jiaChin: '家禽',
  dongWu_yehShou: '野兽',
  fangSiang_bei: '北',
  fangSiang_dong: '东',
  fangSiang_nan: '南',
  fangSiang_si: '西',
  haiSian_huLu: '葫芦',
  haiSian_ji: '鸡',
  haiSian_jinChian: '金钱',
  haiSian_sia: '虾',
  haiSian_sieh: '蟹',
  haiSian_yu: '鱼',
  he: '和',
  hot: '热',
  jhihHe_he: '合',
  jhihHe_jhih: '质',
  jiZhi_he: '和',
  jiZhi_jiDa: '极大',
  jiZhi_jiXiao: '极小',
  longHu_he: '和',
  longHu_hu: '虎',
  longHu_long: '龙',
  maJiang_bai: '白',
  maJiang_fa: '发',
  maJiang_jhong: '中',
  nanNyu_nan: '男',
  nanNyu_nyu: '女',
  now_lost: '现在遗漏',
  puke_banShun: '半顺',
  puke_baoZih: '豹子',
  puke_dueiZih: '对子',
  puke_shunZih: '顺子',
  puke_zaLiou: '杂六',
  shengPingJiang_jiang: '降',
  shengPingJiang_ping: '平',
  shengPingJiang_sheng: '升',
  shengXiao_gou: '狗',
  shengXiao_hou: '猴',
  shengXiao_hu: '虎',
  shengXiao_jhu: '猪',
  shengXiao_ji: '鸡',
  shengXiao_long: '龙',
  shengXiao_ma: '马',
  shengXiao_niou: '牛',
  shengXiao_she: '蛇',
  shengXiao_shu: '鼠',
  shengXiao_tu: '兔',
  shengXiao_yang: '羊',
  sihJi_chiou: '秋',
  sihJi_chun: '春',
  sihJi_dong: '冬',
  sihJi_sia: '夏',
  sihYi_chi: '棋',
  sihYi_chin: '琴',
  sihYi_hua: '画',
  sihYi_shu: '书',
  tianDi_di: '地',
  tianDi_tian: '天',
  warm: '温',
  wuXing_huo: '火',
  wuXing_jin: '金',
  wuXing_mu: '木',
  wuXing_shuei: '水',
  wuXing_tu: '土',
  yanse_he: '和',
  yanse_hong: '红',
  yanse_lan: '蓝',
  yanse_lyu: '绿',
  zuSyuan_baoZih: '豹子',
  zuSyuan_zuLiou: '组六',
  zuSyuan_zuSan: '组三'
}
