<template>
  <div class="content-5">
    <div class="content-5__title">
      <span class="content-5__title__text">属性参照</span>
    </div>
    <div class="content-5__menubar">
      <div class="menubar">

        <div
          v-for="(menuItem, menuIndex) in menubarMap()"
          :key="`menu-${menuIndex}`"
          class="menubar__item"
          :class="{'active': selected === menuItem.type}"
          @click="setTable(menuItem.type)"
        >
          <span class="menubar__item__text">{{ menuItem.label }}</span>
          <div class="menubar__item__lightborder"></div>
        </div>

      </div>
    </div>

    <transition name="tab" mode="out-in">
      <PropTableNumber v-if="isNumberTable" :key="selected" :table-data="tableData[selected]" />
      <PropTableString v-else :key="selected" :table-data="tableData[selected]" />
    </transition>

  </div>
</template>

<script>
import { getPublicRule } from '@/api/public/property'
import PropTableNumber from '@/components/desktop/pages/home/PropTableNumber'
import PropTableString from '@/components/desktop/pages/home/PropTableString'

const numberTable = ['seBo', 'shengXiao', 'wuXing']
const sortRule = [
  'wuXing_jin', 'wuXing_mu', 'wuXing_shuei', 'wuXing_huo', 'wuXing_tu',
  'shengXiao_shu', 'shengXiao_niou', 'shengXiao_hu', 'shengXiao_tu', 'shengXiao_long', 'shengXiao_she', 'shengXiao_ma', 'shengXiao_yang', 'shengXiao_hou', 'shengXiao_ji', 'shengXiao_gou', 'shengXiao_jhu',
  'yanse_hong', 'yanse_lan', 'yanse_lyu'
]
const keyMapping = {
  wuXing_huo: '火',
  wuXing_jin: '金',
  wuXing_mu: '木',
  wuXing_shuei: '水',
  wuXing_tu: '土',
  shengXiao_gou: '狗',
  shengXiao_hou: '猴',
  shengXiao_hu: '虎',
  shengXiao_jhu: '猪',
  shengXiao_ji: '鸡',
  shengXiao_long: '龙',
  shengXiao_ma: '马',
  shengXiao_niou: '牛',
  shengXiao_she: '蛇',
  shengXiao_shu: '鼠',
  shengXiao_tu: '兔',
  shengXiao_yang: '羊',
  yanse_hong: '红波',
  yanse_lan: '蓝波',
  yanse_lyu: '绿波'
}

export default {
  name: 'Porperty',
  components: {
    PropTableNumber,
    PropTableString
  },
  data() {
    return {
      selected: 'seBo',
      tableData: {
        // 色波
        seBo: {
          tableName: '色波对照表',
          group: [
            { title: '', items: [] }
          ]
        },
        // 生肖
        shengXiao: {
          tableName: '生肖对照表',
          group: [
            { title: '', items: [] }
          ]
        },
        // 五行
        wuXing: {
          tableName: '五行对照表',
          group: [
            { title: '', items: [] }
          ]
        },
        // 家禽野獸
        jiaQin: {
          tableName: '家禽野兽对照表',
          group: [
            { title: '家禽', items: ['牛', '马', '羊', '鸡', '狗', '猪'] },
            { title: '野兽', items: ['鼠', '虎', '兔', '龙', '蛇', '猴'] }
          ]
        },
        // 男女生肖
        manNu: {
          tableName: '男女生肖对照表',
          group: [
            { title: '男肖', items: ['鼠', '牛', '虎', '龙', '马', '猴', '狗'] },
            { title: '女肖', items: ['兔', '蛇', '羊', '鸡', '猪'] }
          ]
        },
        // 天地生肖
        tianDi: {
          tableName: '天地生肖对照表',
          group: [
            { title: '天肖', items: ['兔', '马', '猴', '猪', '牛', '龙'] },
            { title: '地肖', items: ['蛇', '羊', '鸡', '狗', '鼠', '虎'] }
          ]
        },
        // 四季生肖
        siJi: {
          tableName: '四季生肖对照表',
          group: [
            { title: '春肖', items: ['虎', '兔', '龙'] },
            { title: '夏肖', items: ['蛇', '马', '羊'] },
            { title: '秋肖', items: ['猴', '鸡', '狗'] },
            { title: '冬肖', items: ['鼠', '牛', '猪'] }
          ]
        },
        // 琴棋书画
        qinQi: {
          tableName: '琴棋书画生肖对照表',
          group: [
            { title: '琴肖', items: ['兔', '蛇', '鸡'] },
            { title: '棋肖', items: ['鼠', '牛', '狗'] },
            { title: '书肖', items: ['虎', '龙', '马'] },
            { title: '画肖', items: ['羊', '猴', '猪'] }
          ]
        },
        // 三色生肖
        sanSe: {
          tableName: '三色生肖对照表',
          group: [
            { title: '红肖', items: ['马', '兔', '鼠', '鸡'] },
            { title: '蓝肖', items: ['蛇', '虎', '猪', '猴'] },
            { title: '绿肖', items: ['羊', '龙', '牛', '狗'] }
          ]
        }
      }
    }
  },
  computed: {
    isNumberTable() {
      return numberTable.includes(this.selected)
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    async getData() {
      const seBoData = getPublicRule('seBo')
      const shengXiaoData = getPublicRule('shengXiao')
      const wuXingData = getPublicRule('wuXing')

      const data = await Promise.all([seBoData, shengXiaoData, wuXingData])

      if (data[0][1] || data[1][1] || data[2][1]) {
        console.log('seBoData', data[0][1])
        console.log('shengXiaoData', data[1][1])
        console.log('wuXingData', data[2][1])
        return
      }

      this.tableData.seBo.group = this.setApiData(data[0][0])
      this.tableData.shengXiao.group = this.setApiData(data[1][0])
      this.tableData.wuXing.group = this.setApiData(data[2][0])
    },
    setApiData(apiData) {
      return Object.keys(apiData).sort((a, b) => sortRule.findIndex((el) => el === a) > sortRule.findIndex((el) => el === b) ? 1 : -1).map(typeKey => {
        return {
          title: keyMapping[typeKey],
          items: apiData[typeKey]
        }
      })
    },
    setTable(type) {
      this.selected = type
    },
    // 靜態 menu 資料
    menubarMap() {
      return [
        { type: 'seBo', label: '色波' },
        { type: 'shengXiao', label: '生肖' },
        { type: 'wuXing', label: '五行' },
        { type: 'jiaQin', label: '家禽野兽' },
        { type: 'manNu', label: '男女生肖' },
        { type: 'tianDi', label: '天地生肖' },
        { type: 'siJi', label: '四季生肖' },
        { type: 'qinQi', label: '琴棋书画' },
        { type: 'sanSe', label: '三色生肖' }
      ]
    }
  }
}
</script>

