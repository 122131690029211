<template>
  <div class="fe-calendar">
    <client-only>
      <my-calendar
        :mark-date="markDates"
        :sunday-start="true"
        :text-top="textTop"
        @changeMonth="changeMonth"
      />
    </client-only>
  </div>
</template>

<script>
import { getCalendar } from '@/api/desktop/calendar'
import moment from 'moment'

export default {
  data() {
    return {
      markDates: [],
      textTop: ['日', '一', '二', '三', '四', '五', '六']
    }
  },
  mounted() {
    this.getCalendar(moment().format('YYYY-MM'))
  },
  methods: {
    async getCalendar(date) {
      const [data, err] = await getCalendar(date)
      if (err) {
        console.error(`[error]:`, err)
        return
      }
      this.markDates = data
    },
    changeMonth(date) {
      const formatArray = date.split('/').slice(0, 2)
      formatArray[1] = formatArray[1] >= 10 ? formatArray[1] : `0${formatArray[1]}`
      const formatInput = formatArray.join('-')
      this.getCalendar(formatInput)
    }
  }
}
</script>

<style lang="scss" scoped>
.fe-calendar {
  & /deep/ {
    .wh_container {
      background-color: #ffffff;
      max-width: 410px;
      .wh_content_all {
        background-color: #ffffff;
        .wh_top_changge {
          margin: 10px 0;
          .wh_jiantou1 {
            width: 12px;
            height: 12px;
            border-top: 2px solid black !important;
            border-left: 2px solid black !important;
          }
          .wh_jiantou2 {
            width: 12px;
            height: 12px;
            border-top: 2px solid black !important;
            border-right: 2px solid black !important;
          }
          .wh_content_li {
            color: black;
            font-family: "PingFang SC", "Apple LiGothic Medium", "Droid Sans", "Microsoft YaHei", "Microsoft JhengHei", "Helvetica Neue", Helvetica, sans-serif;
            line-height: 16px;
            font-size: 16px;
            font-weight: 500;

          }
        }
        .wh_content {
          &:nth-child(2) {
            .wh_content_item:nth-child(7) {
              color:red;
            }
            .wh_content_item:nth-child(1) {
              color:red;
            }
          }
          .wh_content_item {
            color: black;
            .wh_top_tag {
              font-size: 0.875rem;
              line-height: 0.875rem;
            }
            .wh_item_date {
              width: 2rem;
              height: 2rem;
              position: relative;
              top:50%;
              transform:translateY(-50%);
              align-items: center;
              font-size: 0.875rem;
              line-height: 0.875rem;
              font-weight: 400;
              &:hover {
              background-color: #e0e0e0;
              border-radius: 50%;
              min-width: 2rem;
              line-height: 2rem;
              min-height: 2rem;
              text-align: center;
            }
            }
            .wh_isToday {
              background-color: transparent;
              background-color: #F0F0F0!important;
              color: #000000;
              min-width: 2rem;
              text-align: center;
              line-height: 2rem;
              min-height: 2rem;
              border-radius: 50%;
            }
            .wh_other_dayhide {
              color: #adadad;
            }
            .wh_isMark {
              background-color: #178AFF!important;
              color: #ffffff;
              min-width: 0.5rem;
              text-align: center;
              line-height: 0.5rem;
              min-height: 0.5rem;
              border-radius: 50%;
              font-weight: 100;
            }
            .wh_chose_day {
              background-color: transparent;
            }
          }
        }
      }
    }
  }
}
</style>
