import request from '@/utils/request'

// 開獎日期 api

const getCalendar = function(date) {
  return request({
    url: '/v1/cp168/apis/calendar/hk6',
    method: 'get',
    params: {
      date
    }
  })
}

export {
  getCalendar
}
