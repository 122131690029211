<template>
  <div>
    <transition name="layout" mode="out-in">

      <!-- 讀取效果 -->
      <div v-if="isLoading" key="home-loading" class="home-loading">
        <HomeLoading />
      </div>

      <div v-else class="home-content">
        <!-- 輪播 -->
        <client-only>
          <div class="content-1">
            <div class="content-1__ad">
              <swiper v-if="bannerList.length > 0" ref="mySwiper" :options="swiperOption">
                <swiper-slide v-for="(slide, slideIndex) in bannerList" :key="`slide-${slideIndex}`">
                  <a v-if="slide.url" :href="slide.url">
                    <div class="hmoe-bg-img" :style="{backgroundImage: `url('${slide.imgPath}')`, width: '100%', height: '360px'}"></div>
                  </a>
                  <div v-else class="hmoe-bg-img" :style="{backgroundImage: `url('${slide.imgPath}')`, width: '100%', height: '360px'}"></div>
                </swiper-slide>
                <div slot="pagination" class="swiper-pagination"></div>
              </swiper>
            </div>
          </div>
        </client-only>

        <!-- 熱門彩種 ＆ 右邊 sidebar 日曆跟廣告 -->
        <div class="content-pair">
          <div class="content-pair__left" style="width: 865px;">
            <div class="content-3">
              <div class="content-3__content">
                <template v-for="(lottery, cpsIndex) in cpsList">
                  <LotteryItem :key="`cps-${cpsIndex}`" :lottery="lottery" :lottery-detail="cpsDetail[lottery.code]" @changeInfo="getCpsDetail($event)" />
                </template>
              </div>
            </div>
          </div>

          <div class="content-pair__right" style="width: 320px;">
            <!-- 香港六合彩搅珠日期 -->
            <div class="content-4">
              <div class="content-4__title"><span class="content-4__title__text">香港六合彩搅珠日期</span></div>
              <div class="content-4__date">
                <Calendar />
              </div>
              <div class="content-4__detail">
                <div class="c4-detail">
                  <div class="c4-detail__color active"></div><span class="c4-detail__text">搅珠</span>
                </div>
                <div class="c4-detail">
                  <div class="c4-detail__color today"></div><span class="c4-detail__text">当前日期</span>
                </div>
              </div>
            </div>

            <!-- 廣告 目前沒有用到 -->
            <!-- <div
              v-for="(ad, adIndex) in sidebarAds"
              :key="`ad-${adIndex}`"
              class="adver-item"
              :style="{backgroundImage: `url('${ad.imgPath}')`}"
              @click="openUrl(ad.url)"
            >
            </div> -->

            <!-- 预测图库 -->
            <PredictionTuku />

            <!-- 中奖神器 -->
            <Winning />
          </div>
        </div>

        <!-- 属性参照 -->
        <Property />
      </div>
    </transition>

    <!-- 圖片預先加載 -->
    <img v-for="(slide, index) in bannerList" :key="`preload-${index}`" :src="slide.url" style="display: none">
  </div>
</template>

<script>
// import { isMobileDevice } from '@/utils/commonLib'
import LotteryItem from '@/components/desktop/common/LotteryItem'
import Calendar from '@/components/desktop/common/calendar/calendar'
import Property from '@/components/desktop/pages/home/Property'
import HomeLoading from '@/components/desktop/common/skeleton/HomeLoading'
import PredictionTuku from '@/components/desktop/pages/home/PredictionTuku'
import Winning from '@/components/desktop/pages/home/Winning'

export default {
  name: 'WebHome',
  transition: 'layout',
  components: {
    LotteryItem,
    Calendar,
    Property,
    HomeLoading,
    PredictionTuku,
    Winning
  },
  data() {
    return {
      isLoading: true,
      swiperOption: {
        autoplay: {
          delay: 2000
        },
        pagination: {
          el: '.swiper-pagination'
        },
        speed: 500,
        loop: true
      }
    }
  },
  computed: {
    // 輪播
    bannerList() {
      return this.$store.state.app.bannerList
    },
    // 廣告
    // sidebarAds() {
    //   return this.$store.state.app.sidebarAds
    // },
    // 彩種基本資料 (頻率, 中文名, 休市日, icon)
    cpsDetail() {
      return this.$store.state.lottery.cpsDetail
    },
    // 彩種清單
    cpsList() {
      return this.$store.state.lottery.cpsList
    }
  },
  mounted() {
    this.getCpsDetail()
  },
  methods: {
    // 取得彩種資訊
    async getCpsDetail(cpCode) {
      this.$store.dispatch('lottery/getCpsDetail', cpCode).then((val) => {
        if (val) {
          setTimeout(() => {
            this.isLoading = false
          }, 1500)
        }
      })
    },
    openUrl(url) {
      if (url) {
        const win = window.open(url, '_blank')
        win.focus()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.hmoe-bg-img {
  background-position: center center;
  background-size: cover;
}

.adver-item {
  margin: 20px 0;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-position: center center;
  background-size: cover;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
}
</style>
