var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"content-5__content"},[_c('table',[_c('thead',[_c('tr',[_c('th',{attrs:{"colspan":_vm.totalCol}},[_c('span',[_vm._v(_vm._s(_vm.tableData.tableName))])])])]),_vm._v(" "),_c('tbody',[_c('tr',_vm._l((_vm.tableData.group),function(group,index){return _c('td',{key:("group-" + index),staticClass:"red",class:{'blue': group.title ==='蓝波','green': group.title ==='绿波'},attrs:{"colspan":_vm.computerCol(group.items.length)}},[_vm._v("\n          "+_vm._s(group.title)+"\n        ")])}),0),_vm._v(" "),_c('tr',_vm._l((_vm.tdRow.firstRow),function(number,index){return _c('td',{key:("firstRow-" + index),class:{
            'red': _vm.tableData.tableName === '色波对照表' && _vm.computerCol(_vm.tableData.group[0].items.length) > index,
            'blue': _vm.tableData.tableName === '色波对照表' && _vm.computerCol(_vm.tableData.group[0].items.length) <= index && index < _vm.computerCol(_vm.tableData.group[0].items.length) + _vm.computerCol(_vm.tableData.group[1].items.length),
            'green': _vm.tableData.tableName === '色波对照表' && index >= _vm.computerCol(_vm.tableData.group[0].items.length) + _vm.computerCol(_vm.tableData.group[1].items.length),
          }},[_vm._v("\n          "+_vm._s(_vm.padZero(number)))])}),0),_vm._v(" "),_c('tr',_vm._l((_vm.tdRow.secondRow),function(number,index){return _c('td',{key:("secondRow-" + index),class:{
            'red': _vm.tableData.tableName === '色波对照表' && _vm.computerCol(_vm.tableData.group[0].items.length) > index,
            'blue': _vm.tableData.tableName === '色波对照表' && _vm.computerCol(_vm.tableData.group[0].items.length) <= index && index < _vm.computerCol(_vm.tableData.group[0].items.length) + _vm.computerCol(_vm.tableData.group[1].items.length),
            'green': _vm.tableData.tableName === '色波对照表' && index >= _vm.computerCol(_vm.tableData.group[0].items.length) + _vm.computerCol(_vm.tableData.group[1].items.length),
          }},[_vm._v(_vm._s(_vm.padZero(number)))])}),0)])])])}
var staticRenderFns = []

export { render, staticRenderFns }