<template>
  <div>
    <el-dialog
      :visible.sync="vanpopup"
      width="880px"
      :show-close="false"
    >
      <div class="video-header">
        <div>{{ cpsDetail[lotteryCode].name }}</div>
        <div class="close-img" @click="vanpopup = false">
          <img width="20px" height="20px" src="@/assets/style/desktop/img/close.svg" alt="">
        </div>
      </div>
      <iframe
        :src="vanpopup ? `/lottery-animation/lhc_video/index.html?lotteryCode=${lotteryCode}` : ''"
        width="100%"
        height="560"
        scrolling="no"
        frameborder="0"
        style="width: 100%"
      ></iframe>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'VideoDialog',
  props: {
    show: {
      type: Boolean,
      default: false
    },
    lotteryCode: {
      required: true,
      type: String,
      default: ''
    }
  },
  data() {
    return {
      // lotteryCode: this.$route.params.lottery_code
    }
  },
  computed: {
    // 彩種基本資料 (頻率, 中文名, 休市日, icon)
    cpsDetail() {
      return this.$store.state.lottery.cpsDetail
    },
    vanpopup: {
      get() {
        return this.show
      },
      set(value) {
        this.$emit('update:show', value)
      }
    }
  }
}
</script>

<style lang="scss" scoped>

.video-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #ebebeb;
  box-sizing: border-box;
  color: black;
  padding: 0 16px;
  font-size: 20px;
  line-height: 40px;
  font-weight: 500;

  .close-img {
    display: flex;
    cursor: pointer;
  }
}
/deep/  {
  .el-dialog__header {
    display: none;
  }

  .el-dialog__body {
    padding: 0;
  }
}
</style>
