<template>
  <div class="content-5__content">
    <table>
      <thead>
        <tr>
          <th :colspan="totalCol"><span>{{ tableData.tableName }}</span></th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td
            v-for="(group, index) in tableData.group"
            :key="`group-${index}`"
            :colspan="computerCol(group.items.length)"
            class="red"
            :class="{'blue': group.title ==='蓝波','green': group.title ==='绿波'}"
          >
            {{ group.title }}
          </td>
        </tr>
        <tr>
          <td
            v-for="(number, index) in tdRow.firstRow"
            :key="`firstRow-${index}`"
            :class="{
              'red': tableData.tableName === '色波对照表' && computerCol(tableData.group[0].items.length) > index,
              'blue': tableData.tableName === '色波对照表' && computerCol(tableData.group[0].items.length) <= index && index < computerCol(tableData.group[0].items.length) + computerCol(tableData.group[1].items.length),
              'green': tableData.tableName === '色波对照表' && index >= computerCol(tableData.group[0].items.length) + computerCol(tableData.group[1].items.length),
            }"
          >
            {{ padZero(number) }}</td>
        </tr>
        <tr>
          <td
            v-for="(number, index) in tdRow.secondRow"
            :key="`secondRow-${index}`"
            :class="{
              'red': tableData.tableName === '色波对照表' && computerCol(tableData.group[0].items.length) > index,
              'blue': tableData.tableName === '色波对照表' && computerCol(tableData.group[0].items.length) <= index && index < computerCol(tableData.group[0].items.length) + computerCol(tableData.group[1].items.length),
              'green': tableData.tableName === '色波对照表' && index >= computerCol(tableData.group[0].items.length) + computerCol(tableData.group[1].items.length),
            }"
          >{{ padZero(number) }}</td>
        </tr>
      </tbody>
    </table>

  </div>
</template>

<script>
const getColLength = (length) => {
  return Math.ceil(length / 2)
}

export default {
  name: 'PropTableNumber',
  props: {
    tableData: {
      type: Object,
      default: () => {
        return {
          tableName: '',
          group: [
            {
              title: '',
              items: []
            }
          ]
        }
      }
    }
  },
  computed: {
    totalCol() {
      return this.tableData.group.map(item => getColLength(item.items.length)).reduce((a, b) => { return a + b })
    },
    // 第一排數字
    tdRow() {
      let firstRow = []
      let secondRow = []
      this.tableData.group.forEach(group => {
        firstRow = firstRow.concat(group.items.slice(0, getColLength(group.items.length)))
        secondRow = secondRow.concat(group.items.slice(getColLength(group.items.length), group.items.length))
        if (firstRow.length !== secondRow.length) {
          secondRow.push('')
        }
      })
      return { firstRow, secondRow }
    }
  },
  methods: {
    computerCol(itemLength) {
      return Math.ceil(itemLength / 2)
    },
    padZero(num) {
      return String(num).padStart(2, '0')
    }
  }
}
</script>

