<template>
  <div class="timerbox">
    <div v-if="isTimeUP">
      <span class="timerbox__minute__text open-effect">开奖中...</span>
    </div>
    <template v-else>
      <div v-if="restTime.h !== '00'" class="timerbox__hour">
        <span class="timerbox__hour__number">{{ restTime.h }}</span>
        <span class="timerbox__hour__text">时</span>
      </div>
      <div class="timerbox__minute">
        <span class="timerbox__minute__number">{{ restTime.m }}</span>
        <span class="timerbox__minute__text">分</span>
      </div>
      <div class="timerbox__second">
        <span class="timerbox__second__number">{{ restTime.s }}</span>
        <span class="timerbox__second__text">秒</span>
      </div>
    </template>
  </div>
</template>

<script>
import moment from 'moment'

export default {
  name: 'CounterDown',
  model: {
    prop: 'value', // 绑定的值，通过父组件传递
    event: 'change' // 自定义时间名
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    drawTime: {
      requried: true,
      type: [Number, String],
      default: ''
    }
  },
  data() {
    return {
      timer: null,
      restTime: {
        h: '00',
        m: '00',
        s: '00'
      },
      intervalIndex: null
    }
  },
  computed: {
    isTimeUP: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('change', val)
      }
    }
  },
  mounted() {
    // 開始倒數
    this.accurateTime()
  },
  destroyed() {
    // 結束倒數
    clearTimeout(this.timer)
  },
  methods: {
    // 模擬現實時間
    accurateTime() {
      const restTime = 1000 - new Date().getTime() % 1000
      this.timer = setTimeout(() => {
        this.countDown()
        this.accurateTime()
      }, restTime)
    },
    // 倒數
    countDown() {
      if (!this.drawTime) return false
      const msec = this.drawTime - moment().utc().valueOf()

      if (msec < 0) {
        if (!this.isTimeUP) {
          this.isTimeUP = true
          // 觸發 api 更新
          this.$emit('changeInfo')
        }
        return false
      }

      this.isTimeUP = false
      this.restTime.h = String(parseInt(msec / 1000 / 60 / 60)).padStart(2, '0')
      this.restTime.m = String(parseInt(msec / 1000 / 60 % 60)).padStart(2, '0')
      this.restTime.s = String(parseInt(msec / 1000 % 60)).padStart(2, '0')
    }
  }
}
</script>

<style lang="scss" scoped>
.open-effect {
  background-color: #178AFF;
  line-height: 16px;
  color: #ffffff;
  padding: 8px;
  border-radius: 4px;
  animation: open 1.5s linear infinite;
}

@keyframes open {
  0%   {color: #ffffff}
  50%  {color: #178AFF}
  100% {color: #ffffff}
}
</style>
