<template>
  <div class="content-15">
    <div class="content-15__title">
      <div class="content-15__title__left"><span>中奖神器</span></div>
      <div class="content-15__title__right">
        <a class="button-outline" href="" @click.prevent="goToPath('/trend/hk6')">
          <span class="button-outline__text">查看全部</span>
        </a>
      </div>
    </div>
    <div class="content-15__linkbox">
      <a
        v-for="(link, index) in linkMapping"
        :key="index"
        class="button-outline"
        href=""
        @click.prevent="goToPath(link.path)"
      >
        <span class="button-outline__text">{{ link.name }}</span>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Winning',
  data() {
    return {
      linkMapping: [
        { name: '热门号码', path: '/trend/hk6?category=ReMenHaoMaTongJi' },
        { name: '热门生肖', path: '/trend/hk6?category=ReMenShengXiaoTongJi' },
        { name: '单双路珠', path: '/trend/hk6?category=DanShuangLuJhu' },
        { name: '大小路珠', path: '/trend/hk6?category=DaXiaoLuJhu' },
        { name: '波色路珠', path: '/trend/hk6?category=BoSeLuJhu' },
        { name: '正码走势', path: '/trend/hk6?category=ZhengMaZouShi' },
        { name: '特码走势', path: '/trend/hk6?category=TeMaZouShi' },
        { name: '生肖走势', path: '/trend/hk6?category=ShengXiaoZouShi' },
        { name: '长龙', path: '/cltx' }
      ]
    }
  },
  methods: {
    goToPath(path) {
      this.$router.push(path)
    }
  }
}
</script>

<style lang="scss">

</style>
