import request from '@/utils/request'

// footer的屬性參照 api

export const getPublicRule = (type) => {
  return request({
    url: `/v1/public/rule/hk6/${type}`,
    method: 'get'
  })
}
